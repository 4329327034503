import { Outlet } from 'react-router-dom';
import HelmetHead from 'components/HelmetHead';
import { Suspense } from 'react';
import Spinner from 'components/Spinner';
import Footer from 'components/Footer';

const Layout = () => {
  return (
    <>
      <HelmetHead />
      <Suspense fallback={<Spinner />}>
        <main>
          <Outlet />
        </main>
        <Footer />
      </Suspense>
    </>
  );
};

export default Layout;
