import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const noIndexPages = [
  '/terms',
  '/privacy',
  '/about',
  '/returns',
  '/contact',
  '/shopping',
  '/warranty',
];

const TITLE = '1essay.com';

function HelmetHead() {
  const location = useLocation();
  const isNoIndexPage = noIndexPages.includes(location.pathname);

  return (
    <Helmet>
      <title>{TITLE}</title>
      {isNoIndexPage && <meta name="robots" content="noindex" />}
    </Helmet>
  );
}

export default HelmetHead;
