import { NavLink } from 'react-router-dom';
import { Box, Link, styled, Container, Typography } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

function Footer() {
  return (
    <StyledBox>
      <Container>
        <Link
          component={NavLink}
          to="/"
          color="inherit"
          underline="hover"
          variant="body2"
        >
          1essay.com
        </Link>
        <Typography variant="body2" component="span">
          &copy; 2024 All rights reserved
        </Typography>
      </Container>
    </StyledBox>
  );
}

export default Footer;
