import { createTheme } from '@mui/material';

const headingFont = {
  fontFamily: `"Libre Baskerville", serif`,
};

const getTheme = prefersDarkMode => {
  const breakpoints = {
    values: {
      xs: 0,
      sm: 720,
      md: 900,
      lg: 1280,
      xl: 1600,
    },
  };

  return createTheme({
    breakpoints,
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      ...(!prefersDarkMode
        ? {
            // palette values for light mode
            primary: { main: '#CC9972' },
            secondary: { main: '#DFCBB2' },
            error: { main: '#CB6347' },
          }
        : {
            // palette values for dark mode
            primary: { main: 'hsl(194.99, 80%, 31%)' },
            secondary: { main: 'hsl(36, 58%, 37%)' },
            error: { main: 'hsl(15.1, 80%, 30%)' },
          }),
    },
    typography: {
      fontFamily: `"Nunito Sans", sans-serif`,
      h1: headingFont,
      h2: headingFont,
      h3: headingFont,
      h4: headingFont,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
            fontSize: '1.1rem',
            [`@media (width >= ${breakpoints.values.sm}px)`]: {
              fontSize: '1.4rem',
            },
          },
        },
      },
      MuiStack: {
        defaultProps: {
          useFlexGap: true,
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 40,
            [`@media (width >= ${breakpoints.values.md}px)`]: {
              minWidth: 56,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px grey inset',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
    },
  });
};

export default getTheme;
